import { useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Container, TextField } from '@material-ui/core';
import { makeStyles, createStyles, type Theme, useTheme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { FULFILLED } from '@catalogit/common/lib/types/states';
import {
  createCollectionSet,
  updateCollectionSet,
  deleteCollectionSet,
  expungeCollectionSet,
  clearUpdateError
} from '@catalogit/client-api/lib/collections-set/actions.js';
import { isAllowed, AuthObject, AuthAction } from '@catalogit/client-api/lib/utils/access.js';
import { type CITThunkDispatch, type CITState } from '@catalogit/client-api/lib/types/store';
import DeleteDialog from '@catalogit/common-components-ts/lib/DeleteDialog';
import OverlayAppBar from '@catalogit/common-components-ts/lib/overlay-app-bar';
import SectionHeader from '@catalogit/common-components-ts/lib/layout/SectionHeader';
import {
  CITContext,
  type CITContextProps
} from '@catalogit/common-components-ts/lib/cit-context.js';
import withLayer, {
  type InjectedStaticLayerProps
} from '@catalogit/common-components-ts/lib/overlays/with-layer-fc.js';
import ErrorAlert from '@catalogit/common-components-ts/lib/overlays/static-error-fc.js';
import {
  FullHeightColumnFlex,
  VariableColumnFlexContainer,
  VariableFlexOverflowItem
} from '@catalogit/common-components-ts/lib/styled-components';
import InsufficientPermission from '../components/InsufficientPermission.js';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerRoot: {
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2)
      }
    },

    sectionContent: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0, 2, 1, 2),
      marginBottom: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        padding: 0
      }
    },

    infoBox: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },

    toolbarActionButton: {
      color: theme.palette.common.fullWhite,

      '&:disabled': {
        color: theme.palette.common.lightWhite
      }
    }
  })
);

function CollectionSetSettings({
  pushLayer,
  popLayer
}: InjectedStaticLayerProps): React.ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const { currAccount } = useContext<CITContextProps>(CITContext);

  const { id: suid } = useParams<{ id: string }>();
  const [userState, collectionState, collectionSetState, currentCollectionUuid] = useSelector(
    (state: CITState) =>
      [
        state.user,
        state.models.collection,
        state.models.collectionSet,
        state.user.current_collection_uuid
      ] as const
  );

  const collectionSetName = collectionSetState.get(suid)?.get('name');
  const [name, setName] = useState<string>(collectionSetName || '');

  const collectionCount = collectionState.valueSeq().reduce((count, collection) => {
    if (collection.get('collection_set_uuid') == suid) count += 1;
    return count;
  }, 0);

  const dispatch = useDispatch<CITThunkDispatch>();

  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);

  const [inFlight, setInFlight] = useState(false);

  const handleCancel = () => {
    history.goBack();
  };

  const showError = (title: string, message: string): void => {
    pushLayer(<ErrorAlert title={title} message={message} onDidClose={popLayer} />);
  };

  const handleCreate = async () => {
    const partial = dispatch(createCollectionSet(name));
    const action = await partial.promise;

    if (action.meta.state == FULFILLED) {
      const newCuid = action.payload.result;
      history.push('/', { suid: newCuid });
    } else {
      dispatch(expungeCollectionSet(partial.uuid));
      if (action.error.status === 409) {
        showError('Duplicate Name', 'A Folder Group of that name already exists');
      } else {
        showError('Create Error', 'Unexpected error attemping to create Folder Group');
      }
    }
  };

  const handleUpdate = async () => {
    const updatePromise = dispatch(updateCollectionSet(suid, name));
    const action = await updatePromise;

    if (action.meta.state === FULFILLED) {
      if (suid === currentCollectionUuid) {
        history.push('/', {
          suid: currentCollectionUuid
        });
      } else {
        history.push(`/collections/${suid}`);
      }
    } else {
      dispatch(clearUpdateError(suid));
      if (action.error.status === 409) {
        showError('Duplicate Name', 'A Folder Group of that name already exists');
      } else {
        showError('Update Error', 'Unexpected error attemping to update Folder Group');
      }
    }
  };

  const handleDelete = async () => {
    const deletePromise = dispatch(deleteCollectionSet(suid));
    const action = await deletePromise;

    if (action.meta.state == FULFILLED) {
      history.push('/');
    } else {
      dispatch(clearUpdateError(suid));
      showError('Delete Error', 'Unexpected error attempting to delete Folder Group');
    }
  };

  const handleOperation = async (operation: 'create' | 'update' | 'delete') => {
    if (!inFlight) {
      try {
        setInFlight(true);
        const trimmedName = name?.trim();

        if (!trimmedName && operation !== 'delete') {
          showError('Invalid Name', 'Enter a non-blank name for the Folder Group');
          setInFlight(false);
          return;
        }

        switch (operation) {
          case 'create':
            await handleCreate();
            break;
          case 'update':
            await handleUpdate();
            break;
          case 'delete':
            await handleDelete();
            break;
          default: {
            throw new Error('Unsupported operation provided.');
          }
        }
      } catch (error) {
        showError('Unexpected Operation Error', (error as Error)?.message);
        setInFlight(false);
      }
    }
  };

  const handleCreateSaveButton = () => {
    handleOperation(suid ? 'update' : 'create');
  };

  const handleDeleteButton = () => {
    handleOperation('delete');
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const handleDeleteDialog = () => {
    setDisplayDeleteDialog(!displayDeleteDialog);
  };

  // check if the user can update or create a collection
  if (
    (suid && !isAllowed(currAccount, userState, AuthObject.COLLECTION_SET, AuthAction.UPDATE)) ||
    !isAllowed(currAccount, userState, AuthObject.COLLECTION_SET, AuthAction.CREATE)
  ) {
    // ensure permission to view page
    return <InsufficientPermission />;
  }

  return (
    <>
      <FullHeightColumnFlex>
        <OverlayAppBar
          title={suid ? 'Edit Folder Group' : 'Create Folder Group'}
          iconElementLeft={
            <Button className={classes.toolbarActionButton} onClick={handleCancel}>
              Cancel
            </Button>
          }
          iconElementRight={
            <Button
              disabled={inFlight}
              className={classes.toolbarActionButton}
              onClick={handleCreateSaveButton}
            >
              {suid ? 'Save' : 'Create'}
            </Button>
          }
        />
        <VariableColumnFlexContainer>
          <VariableFlexOverflowItem>
            <Container maxWidth='sm' disableGutters classes={{ root: classes.containerRoot }}>
              <Alert severity='info' className={classes.infoBox}>
                Folder Groups allow you to group Folders. The grouping is one level deep only - you
                cannot add Folder Groups to Folder Groups. You add Folders to Folder Groups in the
                Edit Folder view. The Folders in Folder Groups can be published to the HUB but
                Folder Groups proper cannot be published - if you want to publish everything in a
                Folder Group you need to publish each Folder individually.
              </Alert>
              <SectionHeader>Name</SectionHeader>
              <div className={classes.sectionContent}>
                <TextField
                  type='text'
                  label='Name'
                  InputLabelProps={{ shrink: true }}
                  placeholder='Enter a name for this Folder Group'
                  fullWidth={true}
                  margin='normal'
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
              {suid ? (
                <>
                  <div className={classes.sectionContent} style={{ margin: theme.spacing(4, 0) }}>
                    <Button
                      disabled={inFlight}
                      variant='contained'
                      style={{
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.error.main
                      }}
                      onClick={handleDeleteDialog}
                    >
                      Delete Folder Group
                    </Button>
                    {displayDeleteDialog ? (
                      <DeleteDialog
                        title='Delete Folder Group'
                        message={
                          collectionCount
                            ? `${collectionSetName} has ${collectionCount} folder${collectionCount > 1 ? 's' : ''} in it. Are you sure you want to delete ${collectionSetName}?`
                            : `Are you sure you want to delete ${collectionSetName}?`
                        }
                        confirmation={`Delete ${collectionSetName}`}
                        onDidClose={handleDeleteDialog}
                        onDelete={handleDeleteButton}
                      ></DeleteDialog>
                    ) : null}
                  </div>
                </>
              ) : null}
            </Container>
          </VariableFlexOverflowItem>
        </VariableColumnFlexContainer>
      </FullHeightColumnFlex>
    </>
  );
}
export default withLayer(CollectionSetSettings);
