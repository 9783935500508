import React from 'react';

import { useHistory } from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import PrimarySkeleton from '@catalogit/common-components-ts/lib/layout/PrimarySkeleton.js';

export default function InsufficientPermission(): React.ReactElement {
  const history = useHistory();
  const theme = useTheme();

  const handleContinue = () => {
    history.push('/');
  };

  return (
    <PrimarySkeleton>
      <div style={{ margin: theme.spacing(2) }}>
        <Typography gutterBottom>
          Insufficient privilege to access this feature
        </Typography>
        <Button variant='contained' color='primary' onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </PrimarySkeleton>
  );
}
