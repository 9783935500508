import { FULFILLED } from '@catalogit/common/lib/types/states.js';

import { RESET } from '@catalogit/client-api/lib/constants/action-types.js';

import { COLLECTION_SET_DELETE } from '@catalogit/client-api/lib/collections-set/types.js';

import { SET_UI_STATE } from '../../constants/action-types.js';

import type { UIState, UIStateActionTypes } from './types.js';

const initialState: UIState = {
  expanded_collection_sets: []
};

export default function uiStateReducer(state: UIState = initialState, action: UIStateActionTypes) {
  switch (action.type) {
    case RESET: {
      return { ...state, expanded_collection_sets: [] };
    }

    case SET_UI_STATE:
      if (action.meta.state === FULFILLED) {
        return { ...state, expanded_collection_sets: action.payload };
      }
      return state;

    case COLLECTION_SET_DELETE:
      // remove the collection set from any collections that are associated with it
      if (action.meta.state === FULFILLED) {
        return {
          ...state,
          expanded_collection_sets: state.expanded_collection_sets.filter(
            (cuid) => cuid !== action.payload.uuid
          )
        };
      }
      return state;

    default:
      return state;
  }
}
